import React, { useEffect, useState } from "react";
import * as api from "../../../../api/apiCarros";
import BannerDestaque from "./BannerDestaque";
import AuthContext from "../../../hooks/AuthContext";

export default function BannerDestaqueNovo({
  infoJson,
  allinfoText,
  selectedItems,
  addToCompare,
  removeFromCompare,
}) {
  const [ViaturasDestaques, setViaturasDestaques] = useState([]);
  const { state } = React.useContext(AuthContext);
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });
  useEffect(() => {
    const fichaViatura = async () => {
      const viaturas = await api.getAllDestaques(2, infoJson);

      const motas = state.allMotas.filter(
        (item) => item.Destaque === true && item.Vendido === false
      );

      const barcos = state.allBarcos.filter(
        (item) => item.Destaque === true && item.Vendido === false
      );

      const vJuntas = viaturas
        .concat(motas)
        .concat(barcos)
        .sort(
          (a, b) => new Date(b.UltimaAlteracao) - new Date(a.UltimaAlteracao)
        );

      setViaturasDestaques(vJuntas);
    };
    fichaViatura();
  }, [infoJson, state]);

  useEffect(() => {
    allinfoText.Destaques && setAlltexto(allinfoText.Destaques);
  }, [allinfoText]);

  return (
    <>
      <BannerDestaque
        ViaturasDestaques={ViaturasDestaques}
        infoJson={infoJson}
        selectedItems={selectedItems}
        addToCompare={addToCompare}
        removeFromCompare={removeFromCompare}
        alltexto={alltexto}
        autoPlay={false}
      />
    </>
  );
}
