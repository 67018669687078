import React, { useEffect, useState } from "react";
import * as api from "../../../../api/apiCarros";
import AuthContext from "../../../hooks/AuthContext";
import Spacer from "../Spacer";
import BannerDestaque from "./BannerDestaque";

export default function DestaquesBanner({
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,

  allinfoText,
}) {
  const [ViaturasDestaques, setViaturasDestaques] = useState([]);
  const { state } = React.useContext(AuthContext);
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });

  useEffect(() => {
    const fichaViatura = async () => {
      const viaturas = await api.getAllDestaques(
        infoJson.Layout.Destaques.destaquesBanner.TipoDestaques,
        infoJson
      );

      const motas = state.allMotas.filter(
        (item) => item.Destaque === true && item.Vendido === false
      );

      const barcos = state.allBarcos.filter(
        (item) => item.Destaque === true && item.Vendido === false
      );

      const vJuntas = viaturas
        .concat(motas)
        .concat(barcos)
        .sort(
          (a, b) => new Date(b.UltimaAlteracao) - new Date(a.UltimaAlteracao)
        );

      setViaturasDestaques(vJuntas);
    };
    fichaViatura();
  }, [infoJson,state]);

  useEffect(() => {
    allinfoText.Destaques && setAlltexto(allinfoText.Destaques);
  }, [allinfoText]);

  return (
    <>
      {ViaturasDestaques.length !== 0 && (
        <div className={`p-0 position-relative container-fluid position`}>
          <Spacer height="85px" clasName="d-lg-none" />
          <>
            <BannerDestaque
              ViaturasDestaques={ViaturasDestaques}
              infoJson={infoJson}
              selectedItems={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
              alltexto={alltexto}
            />
          </>
        </div>
      )}
    </>
  );
}
